<template>
	<b-col cols="12" md="6" class="p-0">
		<b-card>
			<form @submit.prevent="submit">
				<div class="form-group">
					<label>Tahun * <span class="tooltip_hint" id="hint-1">?</span></label>
					<input type="text" class="form-control" placeholder="2019">
					<b-tooltip target="hint-1" triggers="hover">
						Tahun mulai berlaku penghasilan tidak kena pajak tsb
					</b-tooltip>
				</div>
				<div class="form-group">
					<label>File Excel</label>
					<a href="/files/contoh_kode_akun_pajak.xlsx" class="btn btn-secondary btn-sm m-1" download>
						<download-icon size="1.3x" class="custom-class"></download-icon>
						Contoh File Upload
					</a>
				</div>
				<div class="custom-file">
					<input type="file" class="custom-file-input" id="customFile" required>
					<label for="customFile" class="custom-file-label">
						Pilih File
					</label>
				</div>
				<div class="d-flex align-items-cennter mt-2">
					<button
						type="submit"
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
					>
						Save
					</button>
					&nbsp;
					&nbsp;
					<a
						@click="handleBackButton()"
						variant="secondary"
						class="btn waves-effect waves-float waves-light btn-secondary"
					>
						Cancel
					</a>
				</div>
			</form>
		</b-card>
	</b-col>
</template>

<script>
import {
	BCard,
	BRow, 
	BFormGroup, 
	BFormRadio,  
	BCol,
	BTooltip
} from 'bootstrap-vue'
import { DownloadIcon } from 'vue-feather-icons'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BFormRadio,
		BTooltip,
		DownloadIcon
	},
	methods: {
		handleBackButton() {
			history.back()
		},
	}
}
</script>

<style scoped>
	.tooltip_hint {
		border-radius: 50%;
		background: #000;
		color: #fff;
		padding: 0px 4px;
	}
</style>